import { Button } from 'antd'
import { Link } from 'gatsby'
import './NotFound.less'

function NotFound() {
  return (
    <div className="page-not-found">
      <h1 className="title">404</h1>
      <div className="desc">The Page you're looking for was not found.</div>
      <Link to="/">
        <Button type="primary">Go Back</Button>
      </Link>
    </div>
  )
}

export default NotFound
